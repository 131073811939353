import React from "react"
import { ListGroup, ListGroupItem } from "react-bootstrap"

function Spaces({ spacesOptions, activeSpaces, handleSpaceClick }) {
  return (
    <div>
      <label className="form-label">Color Spaces</label>
      <div style={{ height: "200px", overflow: "auto" }}>
        <ListGroup>
          {
            spacesOptions.map((item) => {
              return (
                <ListGroupItem as="li" active={activeSpaces.includes(item.value)}
                               onClick={() => handleSpaceClick(item.value)}
                               style={{ cursor: "pointer" }}>{item.label}</ListGroupItem>
              )
            })
          }
        </ListGroup>
      </div>
    </div>
  )
}

export default Spaces