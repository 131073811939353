import React from "react"
import Layout from "../../../components/_App/layout"
import Navbar from "../../../components/_App/Navbar"
import Breadcrumbs from "../../../components/Common/Breadcrumbs"
import ColorPalletGenerator from "../../../components/ColorPallets/ColorPalletGenerator"
import Partner from "../../../components/Common/Partner"
import Footer from "../../../components/_App/Footer"
import Seo from "../../../components/_App/seo"
import { Location } from "@reach/router"
import MilestonesAchievedSoFar from "../../../components/Common/MilestonesAchievedSoFar"
import ColorPaletteToolInfo from "../../../components/ColorPallets/ColorPaletteToolInfo"
import "../../../assets/css/tools.css"

const parent = [{
  name: "Tools",
  link: "tools"
}]

function ColorPallets({params}) {

  return (
    <Location>
      {
        ({ location }) => {
          return (
            <Layout>
              <div className="single-tool gradient-backgrounds">
                <Seo title="Free Color Palette Generator Tool" />

                <Navbar />
                <div className="contact-area">
                  <div className="container pt-5 pb-3 text-small "  >
                    <div className="section-title">
                    <h1>Color Palettes Generator</h1>
                    {/* <div className="bar" /> */}
                    </div>
                    <div className="d-none">
                    <Breadcrumbs pageTitle="Color Pallets Generator" parent={parent} />
                    </div>
                  </div>
                </div>
                  <ColorPalletGenerator location={location}  />
                {/*<PageBanner pageTitle="Color Pallets" parent={parent}/>*/}

                <ColorPaletteToolInfo/>

                <Partner />
                {/*<FunFactsArea/>*/}
                <MilestonesAchievedSoFar />
                <Footer />
              </div>
            </Layout>
          )
        }
      }
    </Location>
  )
}


export default ColorPallets