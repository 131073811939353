import React from "react"

function General({ handleChange, data }) {
  return (
      <div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input type="text" className="form-control" id="title" value={data.title} name="title"
                 onChange={(e) => handleChange(e)} />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="description"
                    value={data.description} onChange={(e) => handleChange(e)} />
        </div>
      </div>
  )
}

export default General