import React from "react"
import { isDark } from "color-2-name"

function CoverPage({ contentRef, colorPatches , labelData, rgbToHex, data, file }) {
  return (
    <div className="d-none">
      <div ref={contentRef} id='Cover' style={{ display: "flex", flexDirection: "column", padding: "16px" }}>
        <div style={{
          boxSizing: "border-box",
          padding: "0px",
          display: "flex",
          flexWrap: "wrap",
          width: "700px",
          gap: "10px",
          position: "relative"
        }}>
          {colorPatches.map((color, index) => {
            return (
              <div key={index} style={{
                backgroundColor: color, borderRadius: "8px",
                width: `${colorPatches.length > 6 ? '20.27%' : "192px"}`,
                height: "180px",
                position: "relative"
              }}>
                <p style={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  fontSize: "10px", color: isDark(color) ? "#FFFFFF" : "#000000", fontWeight: 600
                }}>{labelData[`label${index + 1}`]}</p>
                <p style={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  fontSize: "10px", color: isDark(color) ? "#FFFFFF" : "#000000", fontWeight: 600
                }}>{rgbToHex(color).toUpperCase().replace("#", "")}</p>
              </div>
            )
          })}
        </div>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -26,
          color: "black",
          fontSize: "12px",
          width: "600px"
        }}>{data.title}</p>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -40,
          color: "black",
          fontSize: "10px"
          , width: "600px"
        }}>{data.description}</p>
        <img src={file} alt="logo-icon" style={{ position: "absolute", left: 520, bottom: -30, width: "34px" }} />
      </div>
    </div>
  )
}

export default CoverPage