import React from "react"

function Labels({ colorPatches, handleLabelChange, labelData }) {
  return (
    <div>
      <label htmlFor="formGroupExampleInput" className="form-label mb-3">Color Labels</label>
      <div className="mb-3">
        <div className="px-2">
          {
            colorPatches.map((color, index) => {
              return (
                <div className="d-flex gap-3 align-items-center mb-3">
                  <div style={{
                    width: "20%",
                    height: 50,
                    backgroundColor: color,
                    borderRadius: "4px",
                  }} />
                  <input type="text" className="form-control" id="formGroupExampleInput" name="colorLabel"
                         onChange={(e) => handleLabelChange(e, index)} value={labelData[`label${index + 1}`]} />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Labels