import React from "react"

function CustomLogo({ handleFileChange,handleChecked, checked }) {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="inlineFormCheck" onChange={handleChecked} />
        <label className="form-check-label" htmlFor="inlineFormCheck">
          Use my custom logo
        </label>
      </div>
      <input type="file" name="fileUrl" onChange={handleFileChange} disabled={!checked} />
    </div>
  )
}

export default CustomLogo