import React from "react"
import ColorShades from "../ColorShades"

function ShadesPage({ shadeRef,colorPatches, rgbToHex, data, file }) {
  return (
    <div className="d-none">
      <div ref={shadeRef} id="Shades" style={{ display: "flex", flexDirection: "column", padding: "15px 30px" }}>
        <div style={{ width: "570px" }}>
          <div style={{ display: "flex", width: "100%" }}>
            {colorPatches.map((color, index) =>
              <ColorShades color={rgbToHex(color)} isPdfPage={true} isZenMode={false} />
            )}
          </div>
        </div>
        <p style={{
          position: "absolute",
          left: 34,
          bottom: -26,
          color: "black",
          fontSize: "12px",
          width: "600px"
        }}>{data.title}</p>
        <p style={{
          position: "absolute",
          left: 34,
          bottom: -40,
          color: "black",
          fontSize: "10px"
          , width: "600px"
        }}>{data.description}</p>
        <img src={file} alt="logo-icon" style={{ position: "absolute", left: 520, bottom: -26, width: "30px" }} />
      </div>
    </div>
  )
}

export default ShadesPage