import React, { useEffect, useRef, useState } from "react"
import "../../assets/css/color-pallets.css"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import SingleColorPallet from "./SingleColorPallet"
import { GoShareAndroid } from "react-icons/go"
import ExportColorPallet from "./ExportColorPallet"
import ExportPalletDialog from "./ExportPalletDialog"
import ExportColorImages from "./ExportColorImages"
import Modal from "react-bootstrap/Modal"
import { GrClose } from "react-icons/gr"
import { navigate } from "@reach/router"
import { IoIosAdd } from "react-icons/io"
import JoyRide from "./JoyRide"
import { getRandomColor, hexToRgb, rgbToHex } from "../../helpers/ColorHelper"


const ColorPalletGenerator = ({ location }) => {
  const paramArr = location.pathname.slice(21).split("-")
  const paramArrWithHex = paramArr && paramArr.map((e) => `#${e}`)
  const rgbArr = paramArrWithHex.map((e) => hexToRgb(e))

  const [colorPatches, setColorPatches] = useState([])
  const [copiedMessage, setCopiedMessage] = useState("")
  const [lockedPatches, setLockedPatches] = useState([])
  const [showColorPicker, setShowColorPicker] = useState(null)
  const [selectedColor, setSelectedColor] = useState(null)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const colorPalletGeneratorRef = useRef(null)
  const [selectedShade, setSelectedShade] = useState(null)
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [showPdfExport, setShowPdfExport] = useState(false)
  const [showImgExport, setShowImgExport] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [isZenMode, setIsZenMode] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleOpenExportDialog = () => setShowExportDialog(true)
  const handleCloseExportDialog = () => setShowExportDialog(false)
  const handleOpenPdfExportDialog = () => {
    setShowPdfExport(true)
    setShowExportDialog(false)
  }
  const handleClosePdfExportDialog = () => {
    setShowPdfExport(false)
    setShowExportDialog(true)
  }
  const handleOpenImgExportDialog = () => {
    setShowImgExport(true)
    setShowExportDialog(false)
  }
  const handleCloseImgExportDialog = () => {
    setShowImgExport(false)
    setShowExportDialog(true)
  }
  const handleOpenWarningDialog = () => setShowWarningModal(true)
  const handleCloseWarningDialog = () => {
    setShowWarningModal(false)
    setShowTutorial(true)
  }

  const colorPickerRef = useRef()

  const updateURLParams = (params) => {
    navigate(`/tools/color-pallets/${params}`)
  }

  useEffect(() => {
    const hexArr = colorPatches.map((e) => rgbToHex(e))
    const hexArrWithOutHex = hexArr.map((e) => e.replace("#", ""))
    const paramData = hexArrWithOutHex.join("-")
    updateURLParams(paramData)
  }, [colorPatches])


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    handleOpenWarningDialog()
    if (colorPatches.length === 0 && rgbArr.length === 1) {
      generateRandomColors()
    } else {
      setColorPatches(rgbArr)
    }
  }, [])

  const handleColorPickerClose = () => {
    setIsColorPickerOpen(false)
  }

  const handleShadeSelection = (newColorPatches) => {
    setColorPatches(newColorPatches)
  }

  function handleLockClick(index, color) {
    setLockedPatches([...lockedPatches, color])
  }

  function handleUnLockClick(index, color) {
    const newColor = lockedPatches.filter((item) => item !== color)
    setLockedPatches(newColor)
  }


  function handleClick(value) {
    if (value) {
      let hexColor
      if (value.startsWith("rgb")) {
        hexColor = rgbToHex(value).toUpperCase()
      } else {
        hexColor = (value).toUpperCase()
      }

      const el = document.createElement("textarea")
      el.value = hexColor
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)

      setCopiedMessage("Color code copied to clipboard!")

      setTimeout(() => {
        setCopiedMessage("")
      }, 2000)
    }
  }

  const generateRandomColors = () => {
    const newColorPatches = []
    for (let i = 0; i < 5; i++) {
      newColorPatches.push(getRandomColor())
    }
    setColorPatches(newColorPatches)
  }

  const generateColorPatches = () => {
    const newColorPatches = colorPatches.map((color, index) => {
      if (lockedPatches.find((lockedItem) => lockedItem === index)) {
        return color
      } else {
        return getRandomColor()
      }
    })
    setColorPatches(newColorPatches)
  }

  const handleColorPickerChange = (index, newColor) => {
    const rgbColor = `rgb(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b})`
    setColorPatches((prevColorPatches) => {
      const updatedColorPatches = [...prevColorPatches]
      updatedColorPatches[index] = rgbColor
      return updatedColorPatches
    })

    setSelectedColor(newColor.hex)
  }

  function handleColorPickerItem(index) {
    setIsColorPickerOpen(true)
    setShowColorPicker(index)
  }

  const handleKeyPress = (e) => {
    if (showPdfExport || showImgExport) {
      return
    }
    if (e.code === "Space" || e.code === "Enter") {
      e.preventDefault()
      setColorPatches((prevColorPatches) => {
        return prevColorPatches.map((color, index) => {
          if (lockedPatches.find((lockedColor) => lockedColor === color)) {
            return color
          } else {
            return getRandomColor()
          }
        })
      })
    }
    if (e.code === "Escape") {
      setIsZenMode(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [lockedPatches, showPdfExport, showImgExport, showExportDialog])


  function handleCopyUrl() {
    handleCloseExportDialog()
    const el = document.createElement("textarea")
    el.value = location.href
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)

    setCopiedMessage("URL copied to clipboard!")

    setTimeout(() => {
      setCopiedMessage("")
    }, 2000)
  }

  function addColorPalette(value) {
    const updatedColorPatches = [...colorPatches]
    const updatedLockedPatches = [...lockedPatches]

    updatedColorPatches.splice(value + 1, 0, getRandomColor())
    updatedLockedPatches.splice(value + 1, 0, false)

    setColorPatches(updatedColorPatches)
    setLockedPatches(updatedLockedPatches)
  }

  function removeColorPallet(value) {
    const updatedColorPatches = [...colorPatches]
    const updatedLockedPatches = [...lockedPatches]

    updatedColorPatches.splice(value, 1)
    updatedLockedPatches.splice(value, 1)

    setColorPatches(updatedColorPatches)
    setLockedPatches(updatedLockedPatches)
  }


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(colorPatches);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setColorPatches(reorderedItems);
  };



  return (
    <div>
      {/*{showTutorial && }*/}
      <JoyRide colorPatches={colorPatches} showTutorial={showTutorial}/>
      <div ref={colorPalletGeneratorRef}>
        <div className="d-md-flex justify-content-between align-items-center" style={{ padding: "0 5rem 0" }}>
          <p className="d-none d-md-block" style={{ fontSize: 18 }}>Press the spacebar to generate color pallets!</p>
          <div>
            <button className="exportBtn btn" onClick={handleOpenExportDialog}><GoShareAndroid
              style={{ fontSize: 24 }} /> Export
            </button>
          </div>
        </div>
        <div className="text-center mb-4 d-block d-md-none">
          <button className="btn btn-primary " onClick={generateColorPatches}>
            Generate Color Palettes
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="colors" direction={isSmallScreen ? "vertical" :"horizontal"}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="d-flex flex-column flex-md-row position-relative"
                id='colorPallets'
              >
                {colorPatches.map((color, index) => (
                  <Draggable key={color} draggableId={color} index={index} value={color}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{
                          width:`${100 / colorPatches.length}%`,
                          ...provided.draggableProps.style,
                        }}
                        className='draggable-item'
                      >
                        {index === 0 && colorPatches.length < 8 && <div className="tooltip-add-color-btn-container">
                          <button className="add-color-btn"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    addColorPalette(index - 1)
                                  }}>
                            {<IoIosAdd style={{ borderRadius: "100px", backgroundColor: "white", fontSize: 35 }} />}
                          </button>
                          <div className="add-btn-tooltip-content">Add Color</div>
                        </div>}
                        <SingleColorPallet
                        key={index}
                        color={color}
                        index={index}
                        locked={lockedPatches}
                        handleLockClick={handleLockClick}
                        handleUnLockClick={handleUnLockClick}
                        handleClick={handleClick}
                        handleColorPickerChange={handleColorPickerChange}
                        showColorPicker={showColorPicker}
                        lockedPatches={lockedPatches}
                        setShowColorPicker={setShowColorPicker}
                        selectedColor={selectedColor}
                        rgbToHex={rgbToHex}
                        colorPatches={colorPatches}
                        handleColorPickerClose={handleColorPickerClose}
                        setSelectedColor={setSelectedColor}
                        setColorPatches={setColorPatches}
                        handleShadeSelection={handleShadeSelection}
                        selectedShade={selectedShade}
                        setSelectedShade={setSelectedShade}
                        colorPickerRef={colorPickerRef}
                        isZenMode={isZenMode}
                        dragHandleProps={provided.dragHandleProps}
                        setIsZenMode={setIsZenMode}
                        addColorPalette={addColorPalette}
                        removeColorPallet={removeColorPallet}
                        palletRef={colorPalletGeneratorRef}
                        handleColorPickerItem={handleColorPickerItem}
                        isColorPickerOpen={isColorPickerOpen}
                        isSmallScreen={isSmallScreen}
                      /></div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {copiedMessage && (
                  <p className="popup">
                    {copiedMessage}
                  </p>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Modal centered show={showWarningModal} onHide={handleCloseWarningDialog}>
        <Modal.Body>
          <GrClose onClick={handleCloseWarningDialog} style={{ cursor: "pointer" }} />
          <h1 className="h1 text-center">Hello!</h1>
          <p className=" text-center mx-4" style={{ fontFamily: "Inter", color: "gray", fontSize: 14 }}>Ready to see how
            fun and easy is to create a palette with Color Palette tool?</p>
          <p className=" text-center mx-4" style={{ fontFamily: "Inter", color: "gray", fontSize: 14 }}>Warning: fast
            blinking colors may potentially trigger seizures for people with photosensitive epilepsy. Viewer discretion
            is advised.</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseWarningDialog} className="btn btn-primary w-100">Let's go!</button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showExportDialog} onHide={handleCloseExportDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Export Palette</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExportPalletDialog colorPatches={colorPatches} rgbToHex={rgbToHex}
                              handleOpenPdfExportDialog={handleOpenPdfExportDialog}
                              handleOpenImgExportDialog={handleOpenImgExportDialog}
                              handleCopyUrl={handleCopyUrl}
          />
        </Modal.Body>
      </Modal>
      <Modal centered show={showPdfExport} onHide={handleClosePdfExportDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            Export pallets as Pdf
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExportColorPallet colorPatches={colorPatches} rgbToHex={rgbToHex} setShowPdfExport={setShowPdfExport}
                             setShowImgExport={setShowImgExport} setShowExportDialog={setShowExportDialog} />
        </Modal.Body>
      </Modal>
      <Modal centered show={showImgExport} onHide={handleCloseImgExportDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            Export pallets as Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "static" }}>
          <ExportColorImages colorPatches={colorPatches} rgbToHex={rgbToHex} setShowPdfExport={setShowPdfExport}
                             setShowImgExport={setShowImgExport} setShowExportDialog={setShowExportDialog} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ColorPalletGenerator
