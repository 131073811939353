import React from "react"

function ImgPage1({ contentRef, colorPatches, colorDetails, labelData, file, data  }) {
  return (
    <div ref={contentRef} style={{
      display: "flex",
      overflow: "hidden",
      flexDirection: "column",
      position: "absolute",
      zIndex: "-2",
      height: 320,
      top: -10000,
      left: 0
    }}>
      <div style={{
        boxSizing: "border-box",
        display: "flex",
        position: "relative",
        marginBottom: "6px",
        width: "100%",
        gap: "3px",
        padding: "14px 14px 0 14px",
        overflow: "hidden"
      }}>
        {colorPatches.map((color, index) => {
          return (
            <div key={index} style={{
              backgroundColor: color,
              width: "91.5px",
              height: "200px",
              position: "relative"
            }}>
            </div>
          )
        })}
      </div>
      <div style={{ display: "flex", gap: "4px", padding:"0 14px" }}>
        {colorDetails.map((details, index) => (
          <div key={index}>
            <p style={{
              fontSize: 6,
              fontWeight: 600,
              color: "black",
              width: "100%",
              marginBottom: "0",
              marginLeft: "7px"
            }}> {labelData[`label${index + 1}`]}</p>
            {Object.entries(details).map(([key, value]) => (
              <div key={key} style={{
                fontSize: 5,
                display: "flex",
                justifyContent: "space-between",
                width: "90.5px",
                lineHeight: "10px",
                paddingLeft: "8px",
                paddingRight: "8px"
              }}>
                <p style={{ margin: 0, color: "gray" }}>{key.toUpperCase()}</p>
                <p style={{ color: "black" }}>{value}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <p style={{ position: "absolute", left: 18, bottom: -5, color: "black", fontSize: 8 }}>{data.title}</p>
      <img src={file} alt="logo-icon" style={{ position: "absolute", left: 430, bottom: 2, width: "30px" }} />
    </div>
  )
}

export default ImgPage1