import React, { useEffect, useRef, useState } from "react"
import chroma from "chroma-js"
import html2canvas from "html2canvas"
import Logo from "../../../public/icons/icon-256x256.png"
import { closest } from "color-2-name"
import CustomLogo from "./ExportPdfTabs/CustomLogo"
import Labels from "./ExportPdfTabs/Labels"
import GeneralTab from "./ExportImgTabs/GeneralTab"
import ImgPage1 from "./DownloadImage/ImgPage1"

function ExportColorImages({ colorPatches, setShowImgExport, setShowPdfExport, setShowExportDialog }) {
  const [data, setData] = useState({
    title: ""
  })
  const [labelData, setLabelData] = useState({
    label1: "",
    label2: "",
    label3: "",
    label4: "",
    label5: ""
  })
  const [activeTab, setActiveTab] = useState(1)
  const [selectedSpaces, setSelectedSpaces] = useState(["HEX"])
  const [activeSpaces, setActiveSpaces] = useState(["HEX"])
  const [colorDetails, setColorDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [file, setFile] = useState(Logo)

  const contentRef = useRef(null)

  const handleSpaceClick = (value) => {
    const updatedSpaces = activeSpaces.includes(value)
      ? activeSpaces.filter((space) => space !== value)
      : [...activeSpaces, value]

    setActiveSpaces(updatedSpaces)
    setSelectedSpaces(updatedSpaces)
  }

  function handleChange(e) {
    const { name, value } = e.target
    setData((preValue) => {
      return {
        ...preValue,
        [name]: value
      }
    })
  }

  const handleCloseAllDialog = () => {
    setIsLoading(false)
    setShowExportDialog(false)
    setShowPdfExport(false)
    setShowImgExport(false)
  }


  const handleLabelChange = (e, labelIndex) => {
    const labelFieldName = `label${labelIndex + 1}`
    setLabelData((prevLabelData) => ({
      ...prevLabelData,
      [labelFieldName]: e.target.value
    }))
  }

  const handleDownloadImage = () => {
    setIsLoading(true)
    const imageWidth = 1600
    html2canvas(contentRef.current, {
      scale: imageWidth / contentRef.current.offsetWidth
    }).then((canvas) => {
      setData({ title: "", colorLabel: "" })
      setFile(Logo)
      const imgData = canvas.toDataURL("image/png")

      const downloadLink = document.createElement("a")
      downloadLink.href = imgData
      downloadLink.download = "image.png"

      downloadLink.click()
      handleCloseAllDialog()
    })
  }

  useEffect(() => {
    colorSpaceValues()
  }, [activeSpaces])



  const colorSpaceValues = (color) => {

    const colorData = colorPatches.map((colorValue) => {
      const spaceDetails = {}

      if (selectedSpaces.includes("HEX")) {
        const color = chroma(colorValue)
        spaceDetails.hex = color.hex().toUpperCase()
      }

      if (selectedSpaces.includes("RGB")) {
        spaceDetails.rgb = chroma(colorValue).rgb().join(", ")
      }

      if (selectedSpaces.includes("HSB")) {
        spaceDetails.hsb = chroma(colorValue).hsv().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("CMYK")) {
        spaceDetails.cmyk = chroma(colorValue).cmyk().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("LAB")) {
        spaceDetails.lab = chroma(colorValue).lab().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("Name")) {
        spaceDetails.name = closest(colorValue).name
      }

      return spaceDetails
    })

    setColorDetails(colorData)
  }

  const spacesOptions = [
    { value: "HEX", label: "HEX" },
    { value: "RGB", label: "RGB" },
    { value: "HSB", label: "HSB" },
    { value: "CMYK", label: "CMYK" },
    { value: "LAB", label: "LAB" },
    { value: "Name", label: "Name" }
  ]

  const handleChecked = (e) => {
    setChecked(e.target.checked)
  }

  const handleFileChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]))
  }

  const tabContent = [
    <GeneralTab spacesOptions={spacesOptions} handleSpaceClick={handleSpaceClick} activeSpaces={activeSpaces} handleChange={handleChange} data={data}/>,

    <Labels labelData={labelData} handleLabelChange={handleLabelChange} colorPatches={colorPatches}/>,

    <CustomLogo checked={checked} handleChecked={handleChecked} handleFileChange={handleFileChange}/>
  ]


  return (
    <>
      <div style={{ minHeight: "11rem" }}>
        <ul className="nav justify-content-center mb-4">
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 1 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              General
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 2 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Labels
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 3 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Options
            </button>
          </li>
        </ul>
        {tabContent[activeTab - 1]}
      </div>

      <button className="btn btn-secondary mt-3 rounded-2 w-100" onClick={handleDownloadImage} data-bs-dismiss="modal">
        {isLoading ? <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> : "Export"}
      </button>

      <ImgPage1 data={data} colorPatches={colorPatches} labelData={labelData} contentRef={contentRef} colorDetails={colorDetails} file={file}/>
    </>
  )
}


export default ExportColorImages