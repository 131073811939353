import React, { useRef, useState } from "react"
// import jsPDF from "jspdf"
import Logo from "../../../public/icons/icon-256x256.png"
import chroma from "chroma-js"
import { closest } from "color-2-name"
import General from "./ExportPdfTabs/General"
import Labels from "./ExportPdfTabs/Labels"
import Spaces from "./ExportPdfTabs/Spaces"
import Pages from "./ExportPdfTabs/Pages"
import CustomLogo from "./ExportPdfTabs/CustomLogo"
import CoverPage from "./DownloadPdf/CoverPage"
import ColorPage from "./DownloadPdf/ColorPage"
import ShadesPage from "./DownloadPdf/ShadesPage"
import ContrastPage from "./DownloadPdf/ContrastPage"
import TemperaturePage from "./DownloadPdf/TemperaturePage"

function ExportColorPallet({
                             colorPatches,
                             rgbToHex,
                             setShowExportDialog,
                             setShowPdfExport,
                             setShowImgExport
                           }) {
  const [data, setData] = useState({
    title: "",
    description: ""
  })
  const [labelData, setLabelData] = useState({
    label1: "",
    label2: "",
    label3: "",
    label4: "",
    label5: ""
  })
  const [activeTab, setActiveTab] = useState(1)
  const [selectedSpaces, setSelectedSpaces] = useState(["HEX"])
  const [selectedPages, setSelectedPages] = useState(["Cover"])
  const [colorSpaceDetails, setColorSpaceDetails] = useState([])
  const [colorPageDetails, setColorPageDetails] = useState([])
  const [activeSpaces, setActiveSpaces] = useState(["HEX"])
  const [activePages, setActivePages] = useState(["Cover"])
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [file, setFile] = useState(Logo)


  const handleSpaceClick = (value) => {
    const updatedSpaces = activeSpaces.includes(value)
      ? activeSpaces.filter((space) => space !== value)
      : [...activeSpaces, value]

    setActiveSpaces(updatedSpaces)
    setSelectedSpaces(updatedSpaces)
  }

  const handlePageClick = (value) => {
    const updatedPages = activePages.includes(value)
      ? activePages.filter((page) => page !== value)
      : [...activePages, value]

    setActivePages(updatedPages)
    setSelectedPages(updatedPages)
  }


  const contentRef = useRef()
  const colorRef = useRef()
  const shadeRef = useRef()
  const contrastRef = useRef()
  const pagesRef = useRef()

  const handleCloseAllDialog = () => {
    setIsLoading(false)
    setShowExportDialog(false)
    setShowPdfExport(false)
    setShowImgExport(false)
  }

  function handleChange(e) {
    const { name, value } = e.target
    setData((preValue) => {
      return {
        ...preValue,
        [name]: value
      }
    })
  }

  const handleGeneratePdf = () => {
    const colorData = getColorData()
    setColorSpaceDetails(colorData)

    const pageData = getPageData()
    setColorPageDetails(pageData)

    createAndDownloadMultipagePdf()
  }

  const createAndDownloadMultipagePdf = async () => {
    setIsLoading(true)
    const pageHeight = 446
    const doc = await import('jspdf').then(jspdf => {
      return new jspdf.jsPDF("l", "px", "a4")
    })
    // console.log('doc', doc)
    // const doc = new jsPDF("l", "px", "a4")

    if (selectedPages.includes("Temperature") || selectedPages.includes("Hue") || selectedPages.includes("Saturation") || selectedPages.includes("Luminance")) {
      selectedPages.push("Pages")
    }
    const pageIds = selectedPages.filter((page) =>
      !(page === "Temperature" || page === "Hue" || page === "Luminance" || page === "Saturation")
    )

    const generatePages = (currentIndex) => {
      if (currentIndex >= pageIds.length) {
        const filename = "color-palettes.pdf"
        doc.save(filename)
        handleCloseAllDialog()
        return
      }

      const currentPageId = pageIds[currentIndex]
      const yOffset = currentIndex * pageHeight

      doc.html(document.getElementById(currentPageId), {
        callback: function(pdf) {
          if (currentIndex < pageIds.length - 1) {
            pdf.addPage("a4", "l")
          }
          generatePages(currentIndex + 1)
        },
        x: 0,
        y: yOffset
      })
    }

    generatePages(0)
  }

  const getColorData = () => {
    return colorPatches.map((colorValue) => {
      const details = {}

      if (selectedSpaces.includes("HEX")) {
        const color = chroma(colorValue)
        details.hex = color.hex().toUpperCase()
      }

      if (selectedSpaces.includes("RGB")) {
        details.rgb = chroma(colorValue).rgb().join(", ")
      }

      if (selectedSpaces.includes("HSB")) {
        details.hsb = chroma(colorValue).hsv().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("CMYK")) {
        details.cmyk = chroma(colorValue).cmyk().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("LAB")) {
        details.lab = chroma(colorValue).lab().map((value) => value.toFixed(2)).join(", ")
      }

      if (selectedSpaces.includes("Name")) {
        details.name = closest(colorValue).name
      }

      return details
    })
  }

  const getPageData = () => {
    return colorPatches.map((colorValue) => {
      const details = {}

      if (selectedPages.includes("Temperature")) {
        details.temperature = `${hexToCCT(rgbToHex(colorValue)).toFixed(0)} K`
      }

      if (selectedPages.includes("Luminance")) {
        details.luminance = `${(chroma(rgbToHex(colorValue)).luminance() * 100).toFixed(0)}%`
      }

      if (selectedPages.includes("Hue")) {
        details.hue = chroma(colorValue).hsl()[0].toFixed(2)
      }

      if (selectedPages.includes("Saturation")) {
        details.saturation = `${(chroma(colorValue).hsl()[1] * 100).toFixed(2)}%`
      }

      return details
    })
  }

  const spacesOptions = [
    { value: "HEX", label: "HEX" },
    { value: "RGB", label: "RGB" },
    { value: "HSB", label: "HSB" },
    { value: "CMYK", label: "CMYK" },
    { value: "LAB", label: "LAB" },
    { value: "Name", label: "Name" }
  ]

  const pagesOptions = [
    { value: "Cover", label: "Cover" },
    { value: "Colors", label: "Colors" },
    { value: "Shades", label: "Shades" },
    { value: "Contrast", label: "Contrast" },
    { value: "Temperature", label: "Temperature " },
    { value: "Luminance", label: "Luminance" },
    { value: "Hue", label: "Hue" },
    { value: "Saturation", label: "Saturation" }
    // { value: "Color Blindness", label: "Color Blindness (coming soon)" },
    // { value: "Gradient", label: "Gradient (coming soon)" }
  ]

  const handleChecked = (e) => {
    setChecked(e.target.checked)
  }

  const handleFileChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]))
  }

  const handleLabelChange = (e, labelIndex) => {
    const labelFieldName = `label${labelIndex + 1}`
    setLabelData((prevLabelData) => ({
      ...prevLabelData,
      [labelFieldName]: e.target.value
    }))
  }

  function hexToCCT(hexColor) {
    hexColor = hexColor.replace("#", "")

    const r = parseInt(hexColor.substring(0, 2), 16)
    const g = parseInt(hexColor.substring(2, 4), 16)
    const b = parseInt(hexColor.substring(4, 6), 16)

    const normalizedR = r / 255
    const normalizedG = g / 255
    const normalizedB = b / 255

    const sum = normalizedR + normalizedG + normalizedB
    const cx = normalizedR / sum
    const cy = normalizedG / sum

    return -449 * cx ** 3 + 3525 * cx ** 2 - 6823.3 * cx + 5520.33
  }

  const tabContent = [
    <General data={data} handleChange={handleChange} />,

    <Labels colorPatches={colorPatches} handleLabelChange={handleLabelChange} labelData={labelData} />,

    <Spaces activeSpaces={activeSpaces} handleSpaceClick={handleSpaceClick} spacesOptions={spacesOptions} />,

    <Pages activePages={activePages} pagesOptions={pagesOptions} handlePageClick={handlePageClick} />,

    <CustomLogo handleChecked={handleChecked} handleFileChange={handleFileChange} checked={checked} />
  ]


  return (
    <>
      <div>
        <ul className="nav justify-content-center mb-4">
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 1 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              General
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 2 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Labels
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 3 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              Spaces
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 4 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(4)}
            >
              Pages
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`navigation-toggle-btn ${activeTab === 5 ? "activeTab" : ""}`}
              onClick={() => setActiveTab(5)}
            >
              Options
            </button>
          </li>
        </ul>
        {tabContent[activeTab - 1]}

      </div>

      <button className="btn btn-secondary mt-3 rounded-2 w-100" onClick={handleGeneratePdf} data-bs-dismiss="modal">
        {isLoading ? <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> : "Export"}
      </button>

      <CoverPage file={file} data={data} contentRef={contentRef} labelData={labelData} colorPatches={colorPatches}
                 rgbToHex={rgbToHex} />

      <ColorPage colorPatches={colorPatches} data={data} labelData={labelData} file={file} colorRef={colorRef}
                 colorSpaceDetails={colorSpaceDetails} />

      <ShadesPage data={data} file={file} colorPatches={colorPatches} rgbToHex={rgbToHex} shadeRef={shadeRef}/>

      <ContrastPage data={data} file={file} colorPatches={colorPatches} rgbToHex={rgbToHex} contrastRef={contrastRef}/>

      <TemperaturePage data={data} file={file} colorPatches={colorPatches} labelData={labelData} colorPageDetails={colorPageDetails} pagesRef={pagesRef}/>
    </>
  )
}

export default ExportColorPallet
