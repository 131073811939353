import React, { useState } from "react"
import { isDark } from "color-2-name"

const ColorShades = ({ color, handleShadeClick, isZenMode, isPdfPage }) => {
  const [hoveredShade, setHoveredShade] = useState(null)

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return { r, g, b }
  }

  const rgbToHex = (r, g, b) => {
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`
  }

  const generateLighterShade = (color, percentage) => {
    const { r, g, b } = hexToRgb(color)
    const lighterR = Math.min(255, Math.round(r + (255 - r) * percentage))
    const lighterG = Math.min(255, Math.round(g + (255 - g) * percentage))
    const lighterB = Math.min(255, Math.round(b + (255 - b) * percentage))
    return rgbToHex(lighterR, lighterG, lighterB)
  }

  const generateDarkerShade = (color, percentage) => {
    const { r, g, b } = hexToRgb(color)
    const darkerR = Math.max(0, Math.round(r * (1 - percentage)))
    const darkerG = Math.max(0, Math.round(g * (1 - percentage)))
    const darkerB = Math.max(0, Math.round(b * (1 - percentage)))
    return rgbToHex(darkerR, darkerG, darkerB)
  }

  const lighterShades =
    Array.from({ length: 10 }, (_, index) =>
      generateLighterShade(color, (index + 1) / 12)
    ).reverse()


  const darkerShades = Array.from({ length: 10 }, (_, index) =>
      generateDarkerShade(color, (index + 1) / 12)
    )

  const allShades = lighterShades.concat(darkerShades);


  return (
    <div className="d-flex flex-column w-100">
      <div className="w-100">
        {allShades.map((shade, index) => (
          <div key={index} onClick={() => handleShadeClick(shade)} onMouseEnter={() => setHoveredShade(shade)}
               onMouseLeave={() => setHoveredShade(null)}>
            {isPdfPage ? <p className={`${isZenMode ? "zenMode-colorShade" : "colorShade"}`} style={{ backgroundColor: shade, color: isDark(shade) ? "#FFFFFF" : "#000000", height:"1.2rem", fontSize:8, paddingTop: 1, fontWeight: 500 }} >{shade.toUpperCase()}</p>:
            <p className={`${isZenMode ? "zenMode-colorShade" : "colorShade"}`} style={{ backgroundColor: shade, color: isDark(shade) ? "#FFFFFF" : "#000000",height: isZenMode ? '3.4rem' : '2.5rem' }} >{hoveredShade === shade ? shade.toUpperCase() : null}</p>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ColorShades
