import React from "react"
import { ListGroup, ListGroupItem } from "react-bootstrap"

function Pages({ pagesOptions, activePages, handlePageClick }) {
  return (
    <div>
      <label className="form-label">Pages</label>
      <div style={{ height: "200px", overflow: "auto" }}>
        <ListGroup>
          {
            pagesOptions.map((item) => {
              return (
                <ListGroupItem as="li" active={activePages.includes(item.value)}
                               onClick={() => handlePageClick(item.value)}
                               style={{ cursor: "pointer" }}>{item.label}</ListGroupItem>
              )
            })
          }
        </ListGroup>
      </div>
    </div>
  )
}

export default Pages