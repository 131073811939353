import React from "react"
import Joyride from "react-joyride"

function JoyRide({ colorPatches, showTutorial }) {
  const stepContent = [
    "Like a color? Click on the lock icon to keep it. Now only the best matching colors are picked.",
    "Continue by locking colors. Look how the palette is being created.",
    "Click and space, click and space, click and space...",
    "We have to tell you! it's easy to get addicted and spend all day long smashing keyboard! So stop for now...",
  ];

  const colorPatchesWithoutLast = colorPatches.slice(0, 4);
  const steps = [
    {
      target: '#colorPallets',
      content: 'Hit the spacebar to generate random color palettes.',
      disableBeacon: "true",
    },
    ...colorPatchesWithoutLast.map((color, index) => ({
      target: `.singleColor-${index}`,
      content: stepContent[index],
    })),
    {
      target: '.hexBtn',
      content: `If you already have a color in mind just select it from the color picker.`,
    },
    {
      target: '.exportBtn',
      content: `Now that you're really done you can export it in lots of different formats or just copy the URL and share it.`,
    }
  ]
  return (
    <>
      {showTutorial}
      <Joyride
          run={showTutorial}
        steps={steps}
        continuous={true}
        spotlightClicks={true}
        showSkipButton={true}
        disableScrolling={true}
      />
    </>
  )
}

export default JoyRide