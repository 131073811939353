import React, { useEffect, useState } from "react"
import { ImFilePdf } from "react-icons/im"
import { BsImage } from "react-icons/bs"
import { VscLink } from "react-icons/vsc"

function ExportPalletDialog({ colorPatches, rgbToHex, handleOpenPdfExportDialog, handleOpenImgExportDialog,handleCloseExportDialog, handleCopyUrl }) {

  return (
    <>
      <div className="d-flex p-1 gap-2" style={{ minHeight: "23rem" }}>
        <button className="d-flex flex-column align-items-center btn exportBtns"
                style={{ maxWidth: "109px", maxHeight: "120px" }} onClick={handleOpenPdfExportDialog}>
          <div style={{ fontSize: "40px" }}><ImFilePdf /></div>
          <p className="text-black fw-medium" style={{ fontSize: 16, marginTop: 4 }}>PDF</p>
        </button>
        <button className="d-flex flex-column align-items-center btn exportBtns"
                style={{ maxWidth: "109px", maxHeight: "120px" }} onClick={handleOpenImgExportDialog}>
          <div style={{ fontSize: "40px" }}><BsImage /></div>
          <p className="text-black fw-medium"
             style={{ fontSize: 16, marginTop: 4, textTransform: "capitalize" }}>Image</p>
        </button>
        <button className="d-flex flex-column align-items-center btn exportBtns"
                style={{ maxWidth: "109px", maxHeight: "120px" }} onClick={handleCopyUrl}>
          <div style={{ fontSize: "40px" }}><VscLink /></div>
          <p className="text-black fw-medium"
             style={{ fontSize: 16, marginTop: 4, textTransform: "capitalize" }}>URL</p>
        </button>
        {/*<button className="d-flex flex-column align-items-center btn exportBtns"*/}
        {/*        style={{ maxWidth: "109px", maxHeight: "120px" }}>*/}
        {/*  <div style={{ fontSize: "40px" }}><VscLink /></div>*/}
        {/*  <p className="text-black fw-medium"*/}
        {/*     style={{ fontSize: 16, marginTop: 4, textTransform: "capitalize" }}>URL</p>*/}
        {/*</button>*/}
      </div>

    </>
  )
}

export default ExportPalletDialog