import React from "react"

function TemperaturePage({ pagesRef, colorPatches,colorPageDetails, labelData, data, file  }) {
  return (
    <div className="d-none">
      <div ref={pagesRef} id="Pages" style={{
        display: "flex",
        flexDirection: `${colorPatches.length > 5 ? "row" : "column"}`,
        padding: "16px"
      }}>
        <div style={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: `${colorPatches.length > 5 ? "column" : "row"}`,
          flexWrap: "wrap",
          width: "842px",
          gap: "4px",
          position: "relative"
        }}>
          {colorPatches.map((color, index) => {
            return (
              <div key={index} style={{
                backgroundColor: color,
                width: `${colorPatches.length > 5 ? "440px" : "116px"}`,
                height: `${colorPatches.length > 5 ? "45px" : "290px"}`,
                position: "relative"
              }}>
              </div>
            )
          })}
        </div>
        <div style={{
          display: "flex",
          gap: "4px",
          paddingLeft: `${colorPatches.length > 5 ? "14px" : "0px"}`,
          flexDirection: `${colorPatches.length > 5 ? "column" : "row"}`
        }}>
          {colorPageDetails.map((details, index) => (
            <div key={index} style={{ height: `${colorPatches.length > 5 ? "45px" : "auto"}` }}>
              <p style={{
                fontSize: `${colorPatches.length > 5 ? "5px" : "8px"}`,
                fontWeight: 700,
                color: "black",
                width: "108px",
                marginTop: `${colorPatches.length > 5 ? "2px" : "6px"}`,
                marginBottom: "0",
                marginLeft: "8px"
              }}> {labelData[`label${index + 1}`]}</p>
              {Object.entries(details).map(([key, value]) => (
                <div key={key} style={{
                  fontSize: `${colorPatches.length > 5 ? "4.5px" : "6px"}`,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "116px",
                  lineHeight: "10px",
                  padding: "0 8px"
                }}>
                  <p style={{ margin: 0, color: "gray" }}>{key.toUpperCase()}</p>
                  <p style={{ color: "black" }}>{value}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -25,
          color: "black",
          fontSize: "12px",
          width: "600px"
        }}>{data.title}</p>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -40,
          width: "600px",
          color: "black",
          fontSize: "10px"
        }}>{data.description}</p>
        <img src={file} alt="logo-icon" style={{ position: "absolute", left: 520, bottom: -24, width: "34px" }} />
      </div>
    </div>
  )
}

export default TemperaturePage