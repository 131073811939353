import { BiFullscreen, BiMoveHorizontal, BiSolidLockAlt, BiSolidLockOpenAlt } from "react-icons/bi"
import "../../assets/css/color-pallets.css"
import { SketchPicker } from "react-color"
import React, { useEffect, useRef, useState } from "react"
import { FiCopy } from "react-icons/fi"
import { IoContrastOutline } from "react-icons/io5"
import { BsCheckCircle, BsGrid3X3 } from "react-icons/bs"
import { RxCrossCircled } from "react-icons/rx"
import ColorShades from "./ColorShades"
import { closest, isDark } from "color-2-name"
import { IoIosAdd } from "react-icons/io"
import { MdOutlineClose } from "react-icons/md"

const SingleColorPallet = ({
                             colorPickerRef,
                             color,
                             index,
                             locked,
                             handleLockClick,
                             handleUnLockClick,
                             handleClick,
                             handleColorPickerChange,
                             showColorPicker,
                             lockedPatches,
                             selectedColor,
                             rgbToHex,
                             colorPatches,
                             handleColorPickerClose,
                             setColorPatches,
                             selectedShade,
                             setSelectedShade,
                             isZenMode,
                             setIsZenMode,
                             addColorPalette,
                             removeColorPallet,
                             handleColorPickerItem,
                             isColorPickerOpen, dragHandleProps,isSmallScreen
                           }) => {


  const [contrastColor, setContrastColor] = useState("")
  const [showPopover, setShowPopover] = useState(false)
  const popoverRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)
  const [colorShades, setColorShades] = useState(false)
  const [selectedColorIndex, setSelectedColorIndex] = useState(null)

  const getContrastColor = (background, index) => {
    return isDark(background) ? "#FFFFFF" : "#000000"
  }

  const handleClickContrast = () => {
    const background = rgbToHex(color)
    const betterContrastColor = getContrastColor(background, index)
    setContrastColor(betterContrastColor)
    setShowPopover(true)
  }

  const closePopover = () => {
    setShowPopover(false)
  }

  useEffect(() => {
    const background = rgbToHex(color)
    const betterContrastColor = getContrastColor(background, index)
    setContrastColor(betterContrastColor)
  }, [color, selectedShade])

  const handleShowShades = (index) => {
    setColorShades(true)
    setSelectedColorIndex(index)
  }

  const handleCloseShades = () => {
    setColorShades(false)
  }

  const handleShadeClick = (shade) => {
    setSelectedShade(shade)
    const newColorPatches = [...colorPatches]
    newColorPatches[index] = shade
    setColorPatches(newColorPatches)
  }

  const closestColor = closest(color)
  const { name } = closestColor

  const backgroundColor = locked ? color : "#f2f2f2"

  function openFullscreen() {
    setIsZenMode(true)
    let elem = document.getElementById("colorPallets")
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    }
  }

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    zIndex: -1
  }

  return (

    <div
      className={`singlePallet singleColor-${index}`}
      style={{
        backgroundColor,
        position: "relative"
      }}
      onMouseMove={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="singlePallet h-auto"
        key={index}
        style={{ backgroundColor: color }}
      >
        <div
          className="d-flex flex-row-reverse justify-content-between flex-md-column align-items-center h-75 w-100 m-auto">
          <div className={`d-flex flex-md-column flex-row toggleBtn `} style={{ gap: 20 }}>
            {colorPatches.length > 2 && <div className="tooltip-container">
              <button
                className={`shadeBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                onClick={() => removeColorPallet(index)}
                style={{ color: contrastColor }}
              >
                <MdOutlineClose />
              </button>
              <div className="tooltip-content">Remove color</div>
            </div>}
            <div className="tooltip-container">
              <button
                className={`shadeBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                onClick={() => handleShowShades(index)}
                style={{ color: contrastColor }}
              >
                <BsGrid3X3 />
              </button>
              <div className="tooltip-content">View Shades</div>
            </div>
            <div className="tooltip-container">
              <button className={`contrastBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                      onClick={handleClickContrast}
                      style={{ color: contrastColor }}>
                <IoContrastOutline />
              </button>
              <div className="tooltip-content">Check Contrast</div>
            </div>
            {colorPatches.length < 8 && <div
              className={`tooltip-add-color-btn-container ${index === colorPatches.length - 1 && "last-tooltip-add-color-btn-container"}`}>
              <button className="add-color-btn" onClick={() => addColorPalette(index)}>
                {<IoIosAdd style={{ borderRadius: "100px", backgroundColor: "white", fontSize: 35 }} />}
              </button>
              <div className="add-btn-tooltip-content">Add Color</div>
            </div>}
            <div className="tooltip-container">
              <button className={`${isSmallScreen? 'verticalDragBtn' :'dragBtn'} ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                      style={{ color: contrastColor, position: "relative" }} {...dragHandleProps}
              >
                {<BiMoveHorizontal />}
              </button>
              <div className="tooltip-content">Drag</div>
            </div>
            <div className="tooltip-container">
              <button className={`lockBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`} style={{ color: contrastColor }}>
                {lockedPatches.find((itemColor) => itemColor === color) === color ?
                  <BiSolidLockAlt onClick={() => handleUnLockClick(index, color)} /> :
                  <BiSolidLockOpenAlt onClick={() => handleLockClick(index, color)} />}
              </button>
              <div className="tooltip-content">Toggle Lock</div>
            </div>
            <div className="tooltip-container">
              <button className={`lockBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                      style={{ color: contrastColor }}>
                <FiCopy onClick={() => handleClick(color)} />
              </button>
              <div className="tooltip-content"> Copy HEX</div>
            </div>
            {!isZenMode && <div className="tooltip-container">
              <button
                className={`shadeBtn ${isHovered ? "visibleBtn" : "hiddenBtn"}`}
                onClick={openFullscreen}
                style={{ color: contrastColor }}
              >
                <BiFullscreen />
              </button>
              <div className="tooltip-content">Click to open Zen mode</div>
            </div>}
          </div>
          <div>
            <div className="zenmode-tooltip-container">
              <button className={`hexBtn btn firstHexBtn-${index}`} style={{ color: contrastColor }}
                      onClick={() => handleColorPickerItem(index)}>
                {(selectedColor === color ? selectedColor : rgbToHex(color))}
              </button>
              <div className="zenmode-tooltip-content">Select Color</div>
            </div>

          </div>
          <p className="zenModeBtn colorName pointer-event-none"
             style={{ color: contrastColor, textTransform: "capitalize" }}
          >{name}</p>
        </div>
        {
          showColorPicker === index && isColorPickerOpen &&
          (
            <div style={{ position: "absolute", bottom: 170, left: 80, zIndex: 100 }}>
              <div className="color-picker-container" ref={colorPickerRef}>
                <SketchPicker
                  color={colorPatches[showColorPicker]}
                  onChange={(newColor) => handleColorPickerChange(showColorPicker, newColor)}
                  // onClose={handleColorPickerClose}
                  disableAlpha={true}
                />
                <div style={cover} onClick={handleColorPickerClose} />
              </div>
            </div>
          )
        }
        {showPopover && (
          <div className="popover" ref={popoverRef} style={{ position: "absolute", top: 100, left: 124, borderRadius: "6px" }}>
            <div className="popover-content">
              <div className="d-flex flex-column gap-2 contrastPopover">
                <div className="d-flex align-items-start justify-content-between"><span> White Text </span>
                  <div>{contrastColor === "#FFFFFF" ? <BsCheckCircle fontSize={16} /> :
                    <RxCrossCircled fontSize={18} />}</div>
                </div>
                <div className="d-flex align-items-start justify-content-between"><span> Black Text </span>
                  <div>{contrastColor === "#000000" ? <BsCheckCircle fontSize={16} /> :
                    <RxCrossCircled fontSize={18} />}</div>
                </div>
              </div>
            </div>
            <div style={cover} onClick={closePopover} />
          </div>
        )}
        {colorShades && selectedColorIndex === index && (
          <div
            className="color-shades-overlay"
            onClick={handleCloseShades}
            style={{
              overflow: "hidden",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 3
            }}
          >
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
              <ColorShades color={rgbToHex(color)} handleShadeClick={handleShadeClick} isZenMode={isZenMode}
                           isPdfPage={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleColorPallet