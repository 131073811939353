import React from "react"
import { BsGrid3X3 } from "react-icons/bs"
import { IoColorPalette, IoContrastOutline } from "react-icons/io5"
import { BiFullscreen, BiSolidLockAlt } from "react-icons/bi"
import { FiCopy, FiDownload } from "react-icons/fi"
import { GoShareAndroid } from "react-icons/go"
import { FaKeyboard, FaLock } from "react-icons/fa"

function ColorPaletteToolInfo(props) {
  return (
    <div>
      <div className="page-steps container">
        <h1 className="page-text">
          How to use a color palette generator
        </h1>
        <span className="page-text01">
            A color palette generator is a tool that can help you create color palettes. This can be useful for a variety of purposes, such as designing websites, creating marketing materials, or simply personalizing your home décor.
          </span>
        <div className="page-container01">
          <div className="page-step">
            <div className="page-container02">
              <div className="page-line"></div>
              <div className="page-container03">
                <FaKeyboard size={26} style={{color:"#00000070"}}/>
              </div>
              <div className="page-line1"></div>
            </div>
            <div className="page-container04">
              <h1 className="page-text02">Hit Spacebar to start generating different palettes</h1>
            </div>
          </div>
          <div className="page-step1">
            <div className="page-container05">
              <div className="page-line2" />
              <div className="page-container06">
                <FaLock size={24} style={{color:"#00000070"}}/>
              </div>
              <div className="page-line3"></div>
            </div>
            <div className="page-container07">
              <h1 className="page-text04">Lock the Colors of your choice and randomize the rest of colors till
                you have the final choice.</h1>
            </div>
          </div>
          <div className="page-step3">
            <div className="page-container11">
              <div className="page-line6"></div>
              <div className="page-container12">
                <FiDownload size={26} style={{color:"#00000070"}}/>
              </div>
              <div className="page-line7"></div>
            </div>
            <div className="page-container13">
              <h3 className="page-text10">Once you have the palette of your choice you can export in different
                ways.</h3>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><BsGrid3X3 size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}> The "View Shade" button offers a
              user-friendly way to explore shades. A simple click is all it
              takes to reveal a world of possibilities. We've streamlined the process so that you can focus on
              what matters most – selecting colors that resonate with your project.</p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><IoContrastOutline size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}> Our tool simplifies the process of
              assessing text-background contrast. A simple click on the "Check Contrast" button provides you
              with valuable insights, allowing you to identify which text
              color complements your chosen background color, ensuring your designs meet accessibility
              standards.</p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><BiSolidLockAlt size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}> Embrace the "Lock Color" feature – simply
              click the lock button of your preferred color-palette, and it stays put while you generate new
              palettes with a tap of the spacebar.</p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><FiCopy size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}>Using the "Copy Hex" button is as simple as
              it gets. Click it, and the color code is instantly copied, ready to be pasted wherever you need
              it.</p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><BiFullscreen size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}>"Zen Mode" is a feature that, when
              activated, expands your application to full-screen, providing an immersive and focused
              environment and with a simple press of the "Esc" key, you can seamlessly exit "Zen Mode" and
              return to the regular environment.
            </p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><IoColorPalette size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}>Click on the on the button showing Hex
              code, it
              triggers the color picker tool. This tool presents you with a spectrum of colors, allowing you
              to precisely select any color you desire.
            </p>
          </div>
          <div className="d-flex gap-4 align-items-start mt-3">
            <p><GoShareAndroid size={30} /></p>
            <p className="fs-6" style={{ letterSpacing: "0.6px" }}>Effortlessly share your color palettes by
              exporting them in a variety of versatile formats. Choose from PDF, image, or even obtain a
              direct URL link to your color palette. This comprehensive export feature ensures that you can
              seamlessly integrate your color schemes into different platforms, collaborate effectively, and
              showcase your creative vision exactly the way you intend.
            </p>
          </div>
        </div>
        <div className="mt-5">
          <h4>Export Color pallets as PDf</h4>
          <div className="d-flex flex-column gap-3 mt-4">
            <div className="d-flex flex-column">
              <h5>1. Cover Page : </h5>
              <h6>The cover page showcases the foundational color value in its hex code, along with the
                corresponding label assigned to the color.</h6>
            </div>
            <div className="d-flex flex-column">
              <h5>2. Colors Page : </h5>
              <h6>The Colors page presents color values in diverse formats, such as hex code, RGB, HSB, CMYK,
                LAB, and color name as per the user's selection in the Color Space Options menu. This
                information is accompanied by the chosen title and description.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>3. Shades Page : </h5>
              <h6>The Color Shade page furnishes lighter and darker shade codes corresponding to the color
                palettes, complemented by the provided title and description.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>4. Contrast Page : </h5>
              <h6>The Contrast Page offers appropriate contrast values for text, both in white and black,
                against each background color selected from the color palettes. This aids in ensuring
                readability and visual balance.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>5. Temperature Page : </h5>
              <h6>The Temperature Page provides color temperature values in Kelvin format. Using these values,
                users can select cool and warm colors tailored for specific applications, such as websites and
                other design projects.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>6. Saturation Page : </h5>
              <h6>The Saturation Page supplies color saturation values. With these values, users can opt for
                subdued or vibrant colors suitable for specific applications like websites and other design
                endeavors.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>7. Hue Page : </h5>
              <h6>The Hue Page delivers hue values, enabling users to select distinct color tones tailored for
                specific contexts.
              </h6>
            </div>
            <div className="d-flex flex-column">
              <h5>8. Luminance Page : </h5>
              <h6>The Luminance Page provides luminance values, empowering users to choose optimal brightness
                levels suited for diverse visual applications.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColorPaletteToolInfo