import React from "react"
import { ListGroup, ListGroupItem } from "react-bootstrap"

function GeneralTab({ spacesOptions, handleChange, activeSpaces, handleSpaceClick, data  }) {
  return (
    <div>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" value={data.title} name="title"
               onChange={(e) => handleChange(e)} />
      </div>
      <div className="mb-3">
        <label className="form-label">Color Spaces</label>
        <div style={{ height: "200px", overflow: "auto" }}>
          <ListGroup>
            {
              spacesOptions.map((item) => {
                return (
                  <ListGroupItem as="li" active={activeSpaces.includes(item.value)}

                                 onClick={() => handleSpaceClick(item.value)}
                                 style={{ cursor: "pointer" }}>{item.label}</ListGroupItem>
                )
              })
            }
          </ListGroup>
        </div>
      </div>
    </div>
  )
}

export default GeneralTab