import React from "react"
import { isDark } from "color-2-name"

function ContrastPage({ contrastRef, colorPatches, rgbToHex, data, file }) {
  return (
    <div className="d-none">
      <div ref={contrastRef} id="Contrast" style={{ display: "flex", flexDirection: "column", padding: "16px" }}>
        <p style={{ textAlign: "center", width: "600px", color: "black" }}>Contrast</p>
        <div style={{
          boxSizing: "border-box",
          padding: "0px",
          display: "flex",
          // flexDirection: 'column',
          width: "600px",
          gap: "4px",
          position: "relative"
        }}>
          {colorPatches.map((color, index) => {
            return (
              <div key={index} style={{
                backgroundColor: color,
                width: "100%",
                height: "310px",
                position: "relative"
              }}>
                <p style={{
                  position: "absolute",
                  bottom: 4,
                  left: 10,
                  fontSize: `${colorPatches.length > 5 ? "12px" : "14px"}`,
                  color: isDark(color) ? "#FFFFFF" : "#000000",
                  fontWeight: 600
                }}>{rgbToHex(color).toUpperCase().replace("#", "")}</p>
              </div>
            )
          })}
        </div>
        <div style={{
          boxSizing: "border-box",
          display: "flex",
          // flexDirection: 'column',
          width: "600px",
          gap: "4px",
          position: "relative"
        }}>
          {colorPatches.map((color, index) => {
            return (
              <div key={index} style={{
                marginTop: "4px",
                padding: "0 8px",
                borderRadius: "8px",
                width: "100%"
              }}>
                <p style={{
                  textAlign: "center",
                  color: "black",
                  fontWeight: 600,
                  fontSize: "10px"
                }}>{isDark(color) ? "White Text" : "Black Text"}</p>
              </div>
            )
          })}
        </div>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -26,
          color: "black",
          fontSize: "12px",
          width: "600px"
        }}>{data.title}</p>
        <p style={{
          position: "absolute",
          left: 24,
          bottom: -40,
          color: "black",
          fontSize: "10px"
          , width: "600px"
        }}>{data.description}</p>
        <img src={file} alt="logo-icon" style={{ position: "absolute", left: 520, bottom: -30, width: "40px" }} />
      </div>
    </div>
  )
}

export default ContrastPage